
import { graphql } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { createLocalLink } from "../../utils"
import { Button } from "react-bootstrap"
import {GatsbyImage, getImage} from "gatsby-plugin-image";

export const fragment = graphql`
  fragment RightImageFragment on WpPage_Flexlayouts_FlexibleLayouts_RightImage {  
      sectionHeader
      paragraph
      image {
        altText
        sourceUrl
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
              width: 660
              quality: 75
              placeholder: NONE
            )
          }
        }
     }
      button {
        title
        url
      }
}
`
const RightImage = ({sectionHeader,paragraph,button,image}) => {

  return (

    <section className="p-0 mb-2 mb-lg-5" >
      <Container>
        <Row className={`py-4 flex-column align-items-center flex-md-row `}>
          <Col md={6} className="px-md-0 order-1 order-md-0">
            <div className={`h-100 pt-4 pr-5`}>
              <h2 className={`text-left text-uppercase pt-1 mb-4`} dangerouslySetInnerHTML={{ __html: sectionHeader }} />
              <div className={`h4 font-weight-normal text-left`} dangerouslySetInnerHTML={{ __html: paragraph }} style={{fontSize:"20px"}} />
            </div>
          </Col>
          <Col md={6} className="px-md-0 order-0">
            <div >
              <div className={`position-relative py-5`}>
                {image && <GatsbyImage
                    image={getImage(image.localFile)}
                    alt={image.altText ? image.altText : "Image on the right"}
                    className="w-100 h-100"
                />}
              </div>
            </div>
          </Col>
        </Row>

        <div className="d-flex justify-content-center mb-5">
          {button &&
          <Button href={createLocalLink(button.url)} variant="primary" size="lg">
            {button.title}
          </Button>
          }
        </div>
      </Container>
    </section>

  )
}

export default RightImage
